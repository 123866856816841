<template>
  <div>
    <a
      class="nav-link"
      data-toggle="dropdown"
      href="javascript:void(0)"
      data-animation="scale-up"
      aria-expanded="false"
      role="button"
    >
      <i class="icon wb-flag" aria-hidden="true"></i>
    </a>
    <div class="dropdown-menu" role="menu">
      <a class="dropdown-item" href="javascript:void(0)" role="menuitem">
        <span class="flag-icon flag-icon-gb"></span> English</a
      >
      <a class="dropdown-item" href="javascript:void(0)" role="menuitem">
        <span class="flag-icon flag-icon-fr"></span> French</a
      >
      <a class="dropdown-item" href="javascript:void(0)" role="menuitem">
        <span class="flag-icon flag-icon-cn"></span> Chinese</a
      >
      <a class="dropdown-item" href="javascript:void(0)" role="menuitem">
        <span class="flag-icon flag-icon-de"></span> German</a
      >
      <a class="dropdown-item" href="javascript:void(0)" role="menuitem">
        <span class="flag-icon flag-icon-nl"></span> Dutch</a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
