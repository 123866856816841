<template>
  <nav
    class="site-navbar navbar navbar-default navbar-fixed-top navbar-mega navbar-inverse"
    role="navigation"
    style="z-index: 200"
  >
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggler collapsed"
        data-target="#site-navbar-collapse"
        data-toggle="collapse"
      >
        <i class="icon wb-menu" aria-hidden="true"></i>
      </button>
      <router-link to="/">
        <a class="navbar-brand navbar-brand-center" href="#">
          <img
            class="navbar-brand-logo navbar-brand-logo-normal"
            src="/assets/images/logo.png"
            title="Logo"
          />
          <!-- <img
            class="navbar-brand-logo navbar-brand-logo-special"
            src="/assets/images/logo.png"
            title="Logo"
          /> -->
          <span class="navbar-brand-text hidden-xs-down"> Vaagai </span>
        </a>
      </router-link>
      <!-- <button
        type="button"
        class="navbar-toggler collapsed"
        data-target="#site-navbar-search"
        data-toggle="collapse"
      >
        <span class="sr-only">Toggle Search</span>
        <i class="icon wb-search" aria-hidden="true"></i>
      </button> -->
    </div>

    <div class="navbar-container container-fluid">
      <!-- Navbar Collapse -->
      <div
        class="collapse navbar-collapse navbar-collapse-toolbar"
        id="site-navbar-collapse"
      >
        <!-- Toolbar Left -->
        <toolbar-left></toolbar-left>

        <!-- Navbar Toolbar Right -->
        <toolbar-right></toolbar-right>
      </div>
      <!-- End Navbar Collapse -->

      <!-- Site Navbar Seach -->
      <div class="collapse navbar-search-overlap" id="site-navbar-search">
        <form role="search">
          <div class="form-group">
            <div class="input-search">
              <i class="input-search-icon wb-search" aria-hidden="true"></i>
              <input
                type="text"
                class="form-control"
                name="site-search"
                placeholder="Search..."
              />
              <button
                type="button"
                class="input-search-close icon wb-close"
                data-target="#site-navbar-search"
                data-toggle="collapse"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </form>
      </div>
      <!-- End Site Navbar Seach -->
    </div>
  </nav>
</template>

<script>
import common from "@/Common.vue";
import ToolbarLeft from "@/components/ToolbarLeft.vue";
import ToolbarRight from "@/components/ToolbarRight.vue";
export default {
  mixins: [common],
  components: {
    ToolbarLeft,
    ToolbarRight,
  },
  data() {
    return {
      Data1: {},
      CompanyNameData: {},

      Data2: {},
      CompanyImageData: {},
    };
  },
  watch: {
    Data1: function () {
      console.log("watch Data1");
      var data = this.Data1;
      console.log({ data });
      this.CompanyNameData = data.SettingsValues;
    },

    Data2: function () {
      console.log("watch Data2");
      var data = this.Data2;
      console.log({ data });
      this.CompanyImageData = data.SettingsValues;
    },
  },
  methods: {
    refreshPageData() {
      console.log("navbar refreshPageData called");
      var url1 = "api/app-settings-content";
      var condition1 = {
        UserInterface: 1,
        AppName: "admin_portal",
        SettingsName: "company_name",
      };
      this.getPageDetails("Data1", {}, url1, condition1);

      var url2 = "api/app-settings-content";
      var condition2 = {
        UserInterface: 1,
        AppName: "admin_portal",
        SettingsName: "company_logo",
      };
      this.getPageDetails("Data2", {}, url2, condition2);
    },
  },
  beforeMount() {
    // this.refreshPageData();
  },
};
</script>

<style></style>
