<template>
  <div>
    <ul class="nav navbar-toolbar">
      <template v-for="(megaMenu1, idx1) in megaMenuItems">
        <li :key="idx1" class="nav-item dropdown dropdown-mega">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">
            <i :class="megaMenu1.MenuIcon" :style="megaMenu1.MenuStyle"></i>
            {{ megaMenu1.MenuText }}
          </a>
          <ul class="dropdown-menu" role="menu">
            <div class="mega-content">
              <mega-menu-child-menu :menu="megaMenu1"></mega-menu-child-menu>
            </div>
          </ul>
        </li>
      </template>
      <template v-for="(menu1, index1) in headerMenuItems">
        <li :key="index1" class="nav-item dropdown">
          <template v-if="menu1.ChildMenuCount == 0">
            <parent-menu-item :menu="menu1"></parent-menu-item>
          </template>
          <template v-else>
            <parent-child-menu :menu="menu1"></parent-child-menu>
          </template>
        </li>
      </template>
      <!-- <li class="nav-item dropdown dropdown-fw dropdown-mega">
        <a
          class="nav-link"
          data-toggle="dropdown"
          href="#"
          aria-expanded="false"
          data-animation="fade"
          role="button"
          >Mega <i class="icon wb-chevron-down-mini" aria-hidden="true"></i
        ></a>
        <div class="dropdown-menu" role="menu">
          <div class="mega-content">
            <div class="row">
              <div class="col-md-4">
                <h5>UI Kit</h5>
                <ul class="blocks-2">
                  <li class="mega-menu m-0">
                    <ul class="list-icons">
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../advanced/animation.html">Animation</a>
                      </li>
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../uikit/buttons.html">Buttons</a>
                      </li>
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../uikit/colors.html">Colors</a>
                      </li>
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../uikit/dropdowns.html">Dropdowns</a>
                      </li>
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../uikit/icons.html">Icons</a>
                      </li>
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../advanced/lightbox.html">Lightbox</a>
                      </li>
                    </ul>
                  </li>
                  <li class="mega-menu m-0">
                    <ul class="list-icons">
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../uikit/modals.html">Modals</a>
                      </li>
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../uikit/panel-structure.html">Panels</a>
                      </li>
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../structure/overlay.html">Overlay</a>
                      </li>
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../uikit/tooltip-popover.html ">Tooltips</a>
                      </li>
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../advanced/scrollable.html">Scrollable</a>
                      </li>
                      <li>
                        <i class="wb-chevron-right-mini" aria-hidden="true"></i>
                        <a href="../uikit/typography.html">Typography</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <h5>
                  Media
                  <span class="badge badge-pill badge-success">4</span>
                </h5>
                <ul class="blocks-3">
                  <li>
                    <a class="thumbnail m-0" href="javascript:void(0)">
                      <img
                        class="w-full"
                        src="/global/photos/placeholder.png"
                        alt="..."
                      />
                    </a>
                  </li>
                  <li>
                    <a class="thumbnail m-0" href="javascript:void(0)">
                      <img
                        class="w-full"
                        src="/global/photos/placeholder.png"
                        alt="..."
                      />
                    </a>
                  </li>
                  <li>
                    <a class="thumbnail m-0" href="javascript:void(0)">
                      <img
                        class="w-full"
                        src="/global/photos/placeholder.png"
                        alt="..."
                      />
                    </a>
                  </li>
                  <li>
                    <a class="thumbnail m-0" href="javascript:void(0)">
                      <img
                        class="w-full"
                        src="/global/photos/placeholder.png"
                        alt="..."
                      />
                    </a>
                  </li>
                  <li>
                    <a class="thumbnail m-0" href="javascript:void(0)">
                      <img
                        class="w-full"
                        src="/global/photos/placeholder.png"
                        alt="..."
                      />
                    </a>
                  </li>
                  <li>
                    <a class="thumbnail m-0" href="javascript:void(0)">
                      <img
                        class="w-full"
                        src="/global/photos/placeholder.png"
                        alt="..."
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <h5 class="mb-0">Accordion</h5>
                <div
                  class="panel-group panel-group-simple"
                  id="siteMegaAccordion"
                  aria-multiselectable="true"
                  role="tablist"
                >
                  <div class="panel">
                    <div
                      class="panel-heading"
                      id="siteMegaAccordionHeadingOne"
                      role="tab"
                    >
                      <a
                        class="panel-title"
                        data-toggle="collapse"
                        href="#siteMegaCollapseOne"
                        data-parent="#siteMegaAccordion"
                        aria-expanded="false"
                        aria-controls="siteMegaCollapseOne"
                      >
                        Collapsible Group Item #1
                      </a>
                    </div>
                    <div
                      class="panel-collapse collapse"
                      id="siteMegaCollapseOne"
                      aria-labelledby="siteMegaAccordionHeadingOne"
                      role="tabpanel"
                    >
                      <div class="panel-body">
                        De moveat laudatur vestra parum doloribus labitur
                        sentire partes, eripuit praesenti congressus ostendit
                        alienae, voluptati ornateque accusamus clamat reperietur
                        convicia albucius.
                      </div>
                    </div>
                  </div>
                  <div class="panel">
                    <div
                      class="panel-heading"
                      id="siteMegaAccordionHeadingTwo"
                      role="tab"
                    >
                      <a
                        class="panel-title collapsed"
                        data-toggle="collapse"
                        href="#siteMegaCollapseTwo"
                        data-parent="#siteMegaAccordion"
                        aria-expanded="false"
                        aria-controls="siteMegaCollapseTwo"
                      >
                        Collapsible Group Item #2
                      </a>
                    </div>
                    <div
                      class="panel-collapse collapse"
                      id="siteMegaCollapseTwo"
                      aria-labelledby="siteMegaAccordionHeadingTwo"
                      role="tabpanel"
                    >
                      <div class="panel-body">
                        Praestabiliorem. Pellat excruciant legantur ullum
                        leniter vacare foris voluptate loco ignavi, credo
                        videretur multoque choro fatemur mortis animus
                        adoptionem, bello statuat expediunt naturales.
                      </div>
                    </div>
                  </div>

                  <div class="panel">
                    <div
                      class="panel-heading"
                      id="siteMegaAccordionHeadingThree"
                      role="tab"
                    >
                      <a
                        class="panel-title collapsed"
                        data-toggle="collapse"
                        href="#siteMegaCollapseThree"
                        data-parent="#siteMegaAccordion"
                        aria-expanded="false"
                        aria-controls="siteMegaCollapseThree"
                      >
                        Collapsible Group Item #3
                      </a>
                    </div>
                    <div
                      class="panel-collapse collapse"
                      id="siteMegaCollapseThree"
                      aria-labelledby="siteMegaAccordionHeadingThree"
                      role="tabpanel"
                    >
                      <div class="panel-body">
                        Horum, antiquitate perciperet d conspectum locus
                        obruamus animumque perspici probabis suscipere.
                        Desiderat magnum, contenta poena desiderant concederetur
                        menandri damna disputandum corporum.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li> -->
    </ul>
  </div>
</template>

<script>
import common from "@/Common.vue";
import ParentMenuItem from "@/components/ParentMenuItem.vue";
import ParentChildMenu from "@/components/ParentChildMenu.vue";
import MegaMenuChildMenu from "@/components/MegaMenuChildMenu.vue";

export default {
  mixins: [common],
  components: {
    ParentMenuItem,
    ParentChildMenu,
    MegaMenuChildMenu,
  },
  data() {
    return {
      LoadingFlag: false,

      megaMenuId: "",
      megaMenuItems: [],

      headerMenuId: "",
      headerMenuItems: [],
    };
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getMenuLists("headerMenuId", "headerMenuItems", {
        UserInterface: 1,
      });
      this.getMenuLists("megaMenuId", "megaMenuItems", {
        UserInterface: 2,
      });
    },
    getMenuLists(source, destination, condition) {
      console.log("getMenuLists called");
      console.log("source=" + source + ", destination=" + destination);
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/menu/lists";
        var selectbox1_conditions_array = condition;
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style></style>
