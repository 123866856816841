<template>
  <div>
    <v-row wrap v-if="ProgessStart == 1">
      <v-col align="center" cols="12" md="12">
        <v-progress-circular
          :rotate="180"
          :size="100"
          :width="15"
          :value="UploadPercentage"
          color="#00a4ef"
        >
          {{ UploadPercentage }}
        </v-progress-circular>
        <h6 class="red--text">
          Please wait, we are uploading your details. Time may vary based on
          your internet connection.
        </h6>
      </v-col>
      <br />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Error-6",
  props: {
    ProgessStart: {
      required: true,
    },
    UploadPercentage: {
      required: true,
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style lang="scss">
</style>
