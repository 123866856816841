<template>
  <router-link :to="menu.MenuLink">
    <a
      class="nav-link text-primary"
      :href="`${menu.ChildMenuCount == 0 ? '/#' + menu.MenuLink : '#'}`"
    >
      <i :class="menu.MenuIcon" :style="menu.MenuStyle"></i> {{ menu.MenuText }}
    </a>
  </router-link>
</template>
<script>
export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log("menu item=" + JSON.stringify(this.menu));
  },
};
</script>
<style lang="scss">
#child-menu-item {
  nav a {
    width: max-content !important;
  }
}
</style>