import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";

Vue.config.productionTip = false;

// axios
import axios from "axios";
Vue.prototype.$http = axios;
Vue.prototype.CancelToken = axios.CancelToken;

// Session
import VueSession from "vue-session";
var options = {
  persist: true,
};
Vue.use(VueSession, options);

// vue-toasted

import Toasted from 'vue-toasted';
Vue.use(Toasted);

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective)

// vue-advanced-cropper

import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
Vue.use(Cropper);
Vue.use(CircleStencil);

// v-viewer

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer);

// lazy load for images

import * as VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload);

// CK Editor

import CKEditor from 'ckeditor4-vue';
Vue.use(CKEditor);

import CustomLoader from 'vue-spinner/src/PulseLoader.vue';
Vue.component('custom-loader', CustomLoader);

import DefaultLayout from "@/layouts/DefaultLayout.vue";
Vue.component("default-layout", DefaultLayout);

import BlankLayout from "@/layouts/BlankLayout.vue";
Vue.component("blank-layout", BlankLayout);

import AppNavigation from "@/components/AppNavigation.vue";
Vue.component("app-navigation", AppNavigation);

import UploadPercentageProgress from "@/components/UploadPercentageProgress.vue";
Vue.component("upload-percentage-progress", UploadPercentageProgress);

// vue-json-excel

import JsonExcel from 'vue-json-excel'
Vue.component("JsonExcel", JsonExcel);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
