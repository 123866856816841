<template lang="html">
  <section class="common">
    <h1>common Component</h1>
  </section>
</template>

<script lang="js">

import Swal from "sweetalert2";
import companyConfig from '@/company_config.json'
import * as XLSX from "xlsx/xlsx.mjs";

export default {
    name: 'CommonHelpers',
    props: [],
    mounted() {

    },
    data() {
        return {

        }
    },
    methods: {
        getScreenSize(){
            var breakpointName = this.$vuetify.breakpoint.name;
      console.log({ breakpointName });
      switch (breakpointName) {
        case "xs":
          return "80%";
        case "sm":
          return "95%";
        case "md":
          return "95%";
        case "lg":
          return "1200px";
        case "xl":
          return "1800px";
      }
        },
        sweetAlert(title, message, timer) {
            console.log("sweetAlert called");
            var icon = '';
            switch (title) {
                case 'success':
                    icon = "success";
                    break;
                case 'error':
                    icon = "error";
                    break;
                default:
                    icon = "info";
                    break;
            }
            console.log('icon='+icon);
            var options = {
                title: "",
                text: message,
                icon: icon
            };
            if (timer) {
                options.timer = 1500;
                options.timerProgressBar = true;
                options.showConfirmButton = false;
            }else{
                options.showConfirmButton = true;
            }
            Swal.fire(options);
        },
        toast(title, message, timer = false) {
            console.log("toast called");
            var icon = '';
            switch (title) {
                case 'success':
                    icon = "mdi-check-circle";
                    break;
                case 'error':
                    icon = "mdi-close-circle";
                    break;
                default:
                    icon = "mdi-information";
                    break;
            }
            console.log('icon='+icon);
            var options = {
                position: 'top-right',
                iconPack: 'mdi',
                icon: icon
            };
            if (timer) {
                options.duration = 2000;
                // options.timerProgressBar = true;
                // options.showConfirmButton = false;
            }else{
                options.duration = 6000;
                // options.showConfirmButton = true;
                /*
                action: {
                    icon: 'mdi-close-circle',
                    onClick: (e, toastObject) => {
                    toastObject.goAway(0)
                    },
                }
                */
            }
            options.action = {
                text: 'close',
                onClick: (e, toastObject) => {
                toastObject.goAway(0)
                },
            };
            this.$toasted.show(message, options)
        },
        logout() {

            console.log('logout function is called from common vue');

            // this.$vs.loading({ type:'material', });

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            var url = server_url + 'api/logout';
            console.log('url=' + url);

            var token = this.$session.get('token');
            token = token == (null || undefined) ? "" : token
            console.log('token=' + token);

            if (token != "") {
                this.$http({
                    url: url,
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer '+token
                    }
                })
                .then(function (response) {
                    // this.$vs.loading.close();
                    console.log('response=' + JSON.stringify(response));
                })
                .catch(function (error) {
                    console.log('error=' + error);
                })
            }
            console.log('now you are going to logout');
            this.$session.destroy();
            localStorage.clear();
            this.$router.push('/login');
        },
        getSelectBoxOptions(source, destination, condition, url) {

            console.log('getSelectBoxOptions function is called');

            console.log('source=' + source + ', destination=' + destination + ', condition=' + JSON.stringify(condition) + ', url=' + url);

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            url = server_url + url;
            console.log('url=' + url);

            var token = this.$session.get('token');
            console.log('token=' + token);

            token = token == (null || undefined) ? 0 : token

            // this.pageLoadingOn();

            const thisIns = this;
            var output = "";
            var flag = 0;
            var records = [];
            var totalRecords = 0;

            var sourceValue = thisIns[source];
            console.log('sourceValue=' + sourceValue);
            /*
            var additional = {};
            var n1=condition.length;
            console.log('n1='+n1);
            for (let index = 0; index < n1; index++) {
              var element = condition[index];
              var temp = thisIns[element];
              console.log('index='+index+', element='+element+', temp='+temp);
              if (temp != '') {
                additional[element]=temp;
              }
            }
            console.log('additional='+JSON.stringify(additional));
            */
            var upload = {
                // 'token': token,
                'source': sourceValue,
                'additional': condition,
            };
            console.log('upload=' + JSON.stringify(upload));
            /*
            var conditionValue = condition == '' ?  '' : thisIns[condition];
            console.log('conditionValue='+conditionValue);
            */
            this.$http({
                    url: url,
                    method: 'POST',
                    data: upload,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    }
                })
                .then(function (response) {
                    console.log('response=' + JSON.stringify(response));
                    output = response.data.output;
                    flag = response.data.flag;
                    totalRecords = records.length;
                    console.log('output=' + output + ', flag=' + flag + ', totalRecords=' + totalRecords);
                    // thisIns.pageLoadingOff();
                    if (flag == 1) {
                        records = response.data.records;
                        thisIns[destination] = records;
                    } else {
                        // thisIns.errorMessage(output);
                    }
                })
                .catch(function (error) {
                    console.log('error=' + error);
                })

        },
        getPageDetails(destination, defaultValue, url, condition) {

            console.log('getPageDetails function is called');

            console.log('destination=' + destination +', url=' + url + ', condition=' + JSON.stringify(condition));

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            url = server_url + url;
            console.log('url=' + url);

            var token = this.$session.get('token');
            console.log('token=' + token);

            token = token == (null || undefined) ? 0 : token

            // this.pageLoadingOn();

            const thisIns = this;
            var output = "";
            var flag = 0;
            var records = defaultValue;
            var totalRecords = 0;

            this.$http({
                    url: url,
                    method: 'POST',
                    data: condition,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    }
                })
                .then(function (response) {
                    console.log('response=' + JSON.stringify(response));
                    output = response.data.output;
                    flag = response.data.flag;
                    console.log('output=' + output + ', flag=' + flag);
                    // thisIns.pageLoadingOff();
                    if (flag == 1) {
                        records = response.data.records;
                        thisIns[destination] = records;
                    } else {
                        thisIns[destination] = records;
                        // thisIns.toast("error", output);
                    }
                })
                .catch(function (error) {
                    console.log('error=' + error);
                    thisIns[destination] = records;
                })

        },
        generateExcel(TableData, TableHeader, FileName, SheetName) {
            console.log("generateExcel is called");

            var data = [];
            TableData.forEach((value, index, self) => {
                var data1 = [];
                for (let index1 in TableHeader) {
                var value1 = TableHeader[index1];
                data1[index1] =
                    self[index][value1] == null ||
                    self[index][value1] == undefined
                    ? ""
                    : self[index][value1];
                }
                data.push(data1);
            });

            /* generate worksheet and workbook */
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, SheetName);

            /* create an XLSX file and try to save to Presidents.xlsx */
            XLSX.writeFile(workbook, FileName);
        }
    }
}
</script>

<style lang="scss" scoped>
.common {
  .iconSize {
    font-size: 15px;
  }
}
</style>
