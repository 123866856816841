<template>
  <div
    id="blank-page"
    class="page vertical-align text-center"
    data-animsition-in="fade-in"
    data-animsition-out="fade-out"
  >
    <div
      class="page-content vertical-align-middle animation-slide-top animation-duration-1"
    >
      <slot />

      <footer class="page-copyright page-copyright-inverse">
        <p>Powered by Vaagai Tecknowledge</p>
        <p>© 2022. All RIGHT RESERVED.</p>
        <div class="social">
          <a class="btn btn-icon btn-pure" href="javascript:void(0)">
            <i class="icon bd-twitter" aria-hidden="true"></i>
          </a>
          <a class="btn btn-icon btn-pure" href="javascript:void(0)">
            <i class="icon bd-facebook" aria-hidden="true"></i>
          </a>
          <a class="btn btn-icon btn-pure" href="javascript:void(0)">
            <i class="icon bd-google-plus" aria-hidden="true"></i>
          </a>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#blank-page {
  body {
    padding-top: 0px !important;
  }
}
</style>
