<template>
  <div>
    <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">
      <i :class="menu.MenuIcon" :style="menu.MenuStyle"></i> {{ menu.MenuText }}
    </a>
    <div class="dropdown-menu" role="menu">
      <v-list>
        <template v-for="(menu2, index2) in menu.submenu">
          <template v-if="menu2.ChildMenuCount == 0">
            <child-sub-menu-item :key="index2" :menu="menu2"></child-sub-menu-item>
          </template>
          <template v-else>
            <child-menu-item :key="index2" :menu="menu2"></child-menu-item>
          </template>
        </template>
      </v-list>
    </div>
  </div>
</template>
<script>
import ChildMenuItem from "@/components/ChildMenuItem.vue";
import ChildSubMenuItem from "@/components/ChildSubMenuItem.vue";
export default {
  components: {
    ChildMenuItem,
    ChildSubMenuItem
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log("parent child menu=" + JSON.stringify(this.menu));
  },
  methods: {},
};
</script>
<style lang="scss">
</style>