<template>
  <div>
    <a
      class="nav-link"
      data-toggle="dropdown"
      href="javascript:void(0)"
      title="Messages"
      aria-expanded="false"
      data-animation="scale-up"
      role="button"
    >
      <i class="icon wb-envelope" aria-hidden="true"></i>
      <span class="badge badge-pill badge-info up">3</span>
    </a>
    <div
      class="dropdown-menu dropdown-menu-right dropdown-menu-media"
      role="menu"
    >
      <div class="dropdown-menu-header" role="presentation">
        <h5>MESSAGES</h5>
        <span class="badge badge-round badge-info">New 3</span>
      </div>

      <div class="list-group" role="presentation">
        <div data-role="container">
          <div data-role="content">
            <a
              class="list-group-item"
              href="javascript:void(0)"
              role="menuitem"
            >
              <div class="media">
                <div class="pr-10">
                  <span class="avatar avatar-sm avatar-online">
                    <img src="/global/portraits/2.jpg" alt="..." />
                    <i></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="media-heading">Mary Adams</h6>
                  <div class="media-meta">
                    <time datetime="2018-06-17T20:22:05+08:00"
                      >30 minutes ago</time
                    >
                  </div>
                  <div class="media-detail">
                    Anyways, i would like just do it
                  </div>
                </div>
              </div>
            </a>
            <a
              class="list-group-item"
              href="javascript:void(0)"
              role="menuitem"
            >
              <div class="media">
                <div class="pr-10">
                  <span class="avatar avatar-sm avatar-off">
                    <img src="/global/portraits/3.jpg" alt="..." />
                    <i></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="media-heading">Caleb Richards</h6>
                  <div class="media-meta">
                    <time datetime="2018-06-17T12:30:30+08:00"
                      >12 hours ago</time
                    >
                  </div>
                  <div class="media-detail">
                    I checheck the document. But there seems
                  </div>
                </div>
              </div>
            </a>
            <a
              class="list-group-item"
              href="javascript:void(0)"
              role="menuitem"
            >
              <div class="media">
                <div class="pr-10">
                  <span class="avatar avatar-sm avatar-busy">
                    <img src="/global/portraits/4.jpg" alt="..." />
                    <i></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="media-heading">June Lane</h6>
                  <div class="media-meta">
                    <time datetime="2018-06-16T18:38:40+08:00">2 days ago</time>
                  </div>
                  <div class="media-detail">
                    Lorem ipsum Id consectetur et minim
                  </div>
                </div>
              </div>
            </a>
            <a
              class="list-group-item"
              href="javascript:void(0)"
              role="menuitem"
            >
              <div class="media">
                <div class="pr-10">
                  <span class="avatar avatar-sm avatar-away">
                    <img src="/global/portraits/5.jpg" alt="..." />
                    <i></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="media-heading">Edward Fletcher</h6>
                  <div class="media-meta">
                    <time datetime="2018-06-15T20:34:48+08:00">3 days ago</time>
                  </div>
                  <div class="media-detail">
                    Dolor et irure cupidatat commodo nostrud nostrud.
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="dropdown-menu-footer" role="presentation">
        <a
          class="dropdown-menu-footer-btn"
          href="javascript:void(0)"
          role="button"
        >
          <i class="icon wb-settings" aria-hidden="true"></i>
        </a>
        <a class="dropdown-item" href="javascript:void(0)" role="menuitem">
          See all messages
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
