<template>
  <div>
    <!-- Navbar Toolbar -->
    <ul class="nav navbar-toolbar">
      <li class="nav-item hidden-float" id="toggleMenubar">
        <a class="nav-link" data-toggle="menubar" href="#" role="button">
          <i class="icon hamburger hamburger-arrow-left">
            <span class="sr-only">Toggle menubar</span>
            <span class="hamburger-bar"></span>
          </i>
        </a>
      </li>
      <!-- <li class="nav-item hidden-sm-down" id="toggleFullscreen">
        <a
          class="nav-link icon icon-fullscreen"
          data-toggle="fullscreen"
          href="#"
          role="button"
        >
          <span class="sr-only">Toggle fullscreen</span>
        </a>
      </li> -->
      <!-- <li class="nav-item hidden-float">
        <a
          class="nav-link icon wb-search"
          data-toggle="collapse"
          href="#"
          data-target="#site-navbar-search"
          role="button"
        >
          <span class="sr-only">Toggle Search</span>
        </a>
      </li> -->
    </ul>
    <topbar-menu></topbar-menu>
  </div>
</template>

<script>
import TopbarMenu from "@/components/TopbarMenu.vue";
export default {
  components: {
    TopbarMenu,
  },
};
</script>

<style></style>
