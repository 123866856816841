<template>
  <div>
    <ul class="nav navbar-toolbar navbar-right navbar-toolbar-right">
      <!-- <li class="nav-item dropdown">
        <language-menu></language-menu>
      </li> -->
      <li class="nav-item dropdown">
        <user-menu></user-menu>
      </li>
      <!-- <li class="nav-item dropdown">
        <notifications-menu></notifications-menu>
      </li>
      <li class="nav-item dropdown">
        <messages-menu></messages-menu>
      </li> -->
      <li class="nav-item" id="toggleChat">
        <a class="nav-link" href="#">
          <!-- <i class="icon wb-chat" aria-hidden="true"></i> -->
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import UserMenu from "@/components/UserMenu.vue";
import LanguageMenu from "@/components/LanguageMenu.vue";
import NotificationsMenu from "@/components/NotificationsMenu.vue";
import MessagesMenu from "@/components/MessagesMenu.vue";
export default {
  components: {
    UserMenu,
    LanguageMenu,
    NotificationsMenu,
    MessagesMenu,
  },
};
</script>

<style></style>
