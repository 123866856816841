<template>
  <div>
    <a
      class="nav-link navbar-avatar"
      data-toggle="dropdown"
      href="#"
      aria-expanded="false"
      data-animation="scale-up"
      role="button"
    >
      <span class="avatar avatar-online">
        <!-- <img :src="ProfileImagePath" alt="..." /> -->
        <img
          :src="ProfileImagePath"
          alt="..."
          height="30px"
          width="30px"
          style="border-radius: 100%"
        />
        <i></i>
      </span>
      {{ DisplayName }}
    </a>
    <div class="dropdown-menu" role="menu">
      <router-link to="/my-profile">
        <a class="dropdown-item" href="javascript:void(0)" role="menuitem"
          ><i class="icon wb-user" aria-hidden="true"></i>
          &nbsp; Profile
        </a>
      </router-link>
      <router-link to="/my-account/change-password">
        <a class="dropdown-item" href="javascript:void(0)" role="menuitem"
          ><i class="fa-solid fa-lock"></i>
          &nbsp; Change Password
        </a>
      </router-link>
      <a class="dropdown-item" href="javascript:void(0)" role="menuitem"
        ><i class="fa-solid fa-right-from-bracket"></i>
        <v-btn
          class="text-primary"
          color="#227BD3"
          @click.prevent="logout"
          plain
        >
          Logout
        </v-btn>
      </a>
    </div>
  </div>
</template>

<script>
import Common from "@/Common.vue";
export default {
  mixins: [Common],
  data() {
    return {
      ProfileImagePath: "",
      DisplayName: "",
    };
  },
  beforeMount() {
    var ProfileImagePath = this.$session.get("ProfileImagePath");
    ProfileImagePath =
      ProfileImagePath == (null || undefined) ? "" : ProfileImagePath;
    console.log({ ProfileImagePath });
    this.ProfileImagePath = ProfileImagePath;

    var DisplayName = this.$session.get("DisplayName");
    DisplayName = DisplayName == (null || undefined) ? "" : DisplayName;
    console.log({ DisplayName });
    this.DisplayName = DisplayName;
  },
};
</script>

<style></style>
