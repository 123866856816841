<template>
  <div id="child-sub-menu-item">
    <router-link :to="menu.MenuLink">
      <a
        class="nav-link text-primary"
        :href="`${menu.ChildMenuCount == 0 ? '/#' + menu.MenuLink : '#'}`"
        style="width: max-content !important"
      >
        <i :class="menu.MenuIcon" :style="menu.MenuStyle"></i>
        {{ menu.MenuText }}
      </a>
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log("sub menu item=" + JSON.stringify(this.menu));
  },
};
</script>
<style lang="scss">
#child-sub-menu-item {
  .nav-link {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    width: max-content;
  }
}
</style>