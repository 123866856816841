import Vue from "vue";
import VueRouter from "vue-router";
import companyConfig from "@/company_config.json";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "my-profile",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/DashboardView.vue"),
  },
  {
    path: "/my-account/change-password",
    name: "Change Password",
    component: () => import("@/views/my-account/ChangePassword.vue"),
  },
  {
    path: "/my-profile",
    name: "My Profile",
    component: () => import("@/views/my-account/MyProfile.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "blank" },
    component: () => import("@/views/pages/LoginView.vue"),
  },
  {
    path: "/email-verification",
    name: "Email Verification",
    meta: { layout: "blank" },
    component: () => import("@/views/pages/EmailVerification.vue"),
  },

  // Members

  {
    path: "/members/list",
    name: "Members List",
    component: () => import("@/views/members/MembersList.vue"),
  },
  {
    path: "/members/upload",
    name: "Members Upload",
    component: () => import("@/views/members/MembersUpload.vue"),
  },
  {
    path: "/all-members-list",
    name: "All Members",
    component: () => import("@/views/members/all-members/AllMembersList.vue")
  },

  // App Settings List

  {
    path: "/app/settings/list",
    name: "App Settings",
    component: () => import("@/views/settings/AppWebSettingsList.vue")
  },

  // Events List

  {
    path: "/events/list",
    name: "Events",
    component: () => import("@/views/events/EventsList.vue")
  },
  {
    path: "/events/create",
    name: "Events Create",
    component: () => import("@/views/events/EventsCreate.vue")
  },
  {
    path: "/all-events-list",
    name: "All Events",
    component: () => import("@/views/events/all-events/AllEventsList.vue")
  },
  {
    path: "/events/registration",
    name: "Event Registrations",
    component: () => import("@/views/events/EventRegistrationsList.vue")
  },

  // Event Participants

  {
    path: "/events/participants/list",
    name: "Event Participants",
    component: () => import("@/views/events/participants/EventParticipantsList.vue")
  },
  {
    path: "/events/participants/upload",
    name: "Event Participants Upload",
    component: () => import("@/views/events/participants/EventParticipantsUpload.vue")
  },

  // Event Plans List

  {
    path: "/event-plans/list",
    name: "Event Plans",
    component: () => import("@/views/events/plans/EventPlansList.vue")
  },

  // Amenity Participant List

  {
    path: "/amenity-participant/list",
    name: "Amenity Report",
    component: () => import("@/views/amenity_participant/AmenityParticipantList.vue")
  },

    // Amenity Participant List

    {
      path: "/amenity/list",
      name: "Amenity List",
      component: () => import("@/views/amenity/AmenityList.vue")
    },

  // Designation List

  {
    path: "/designation/list",
    name: "Designation",
    component: () => import("@/views/designation/DesignationList.vue")
  },

  // Team List

  {
    path: "/team/list",
    name: "Team",
    component: () => import("@/views/team/TeamList.vue")
  },

  // Team Member List

  {
    path: "/team-member/list",
    name: "Team Member",
    component: () => import("@/views/team-member/TeamMemberList.vue")
  },

  // Uploaded File List
  {
    path: "/uploaded-file/list",
    name: "Uploaded File List",
    component: () => import("@/views/uploaded_file/UploadedFileList.vue")
  },
  // Uploaded File Create
  {
    path: "/uploaded-file/create",
    name: "Uploaded File Create",
    component: () => import("@/views/uploaded_file/UploadedFileCreate.vue")
  },

  // Page Block List
  {
    path: "/page/settings/list",
    name: "Page Block Settings",
    component: () => import("@/views/settings/page_block/PageBlockSettingsList.vue")
  },

  // User List

  {
    path: "/user/list",
    name: "User",
    component: () => import("@/views/user/UserList.vue")
  },

];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  var ToName = to.name;
  if (ToName) {
    // showLoader();
  }

  var fromPath = from.path;
  var toPath = to.path;
  console.log("navigation from=" + fromPath + ", to=" + toPath);
  var server_url = companyConfig.apiURL;
  console.log({ server_url });

  var token = localStorage.getItem("token");
  token = token == (null || undefined) ? "" : token;
  console.log({ token });

  if (
    to.path == "/" ||
    to.path == "/login" ||
    to.path == "/register" ||
    to.path == "/verify-otp" ||
    to.path == "/forgot-password" ||
    to.path == "/pages/error-404" ||
    to.path == "/pages/error-500" ||
    to.path == "/pages/register" ||
    to.path == "/callback" ||
    to.path == "/pages/not-authorized" ||
    to.path == "/pages/comingsoon"
  ) {
    console.log("navigation true");
    next();
  } else {
    console.log("navigation false");
    if (token != "") {
      console.log("token is valid");
      next();
    } else {
      console.log("session is expired");
      router.push("/login");
    }
  }
});

export default router;
