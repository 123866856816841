<template>
  <div id="mega-menu" class="row">
    <template v-for="(megaMenu2, idx2) in menu.submenu">
      <div :key="idx2" class="col-sm-6 mega-menu">
        <template v-if="megaMenu2.ChildMenuCount == 0">
          <child-sub-menu-item
            :key="idx2"
            :menu="megaMenu2"
          ></child-sub-menu-item>
        </template>
        <template v-else>
          <h5>{{ megaMenu2.MenuText }}</h5>
          <v-list>
            <template v-for="(megaMenu3, idx3) in megaMenu2.submenu">
              <child-sub-menu-item
                :key="idx3"
                :menu="megaMenu3"
              ></child-sub-menu-item>
            </template>
          </v-list>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import ChildSubMenuItem from "@/components/ChildSubMenuItem.vue";
export default {
  components: {
    ChildSubMenuItem,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log("menu item=" + JSON.stringify(this.menu));
  },
};
</script>
<style lang="scss">
#mega-menu {
  .v-sheet.v-list {
    width: max-content !important;
  }
}
</style>